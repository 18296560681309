<template>
  <div class="ai">
    <div class="title">
    </div>
    <div class="carousel">
      <van-swipe :autoplay="3000" :width="267">
        <van-swipe-item class="swipe-item">
          <img
            class="carousel-img"
            src="../../static/img/aibot/screen01.jpg"
          >
        </van-swipe-item>
        <van-swipe-item class="swipe-item">
          <img
            class="carousel-img"
            src="../../static/img/aibot/screen02.jpg"
          >
        </van-swipe-item>
        <van-swipe-item class="swipe-item">
          <img
            class="carousel-img"
            src="../../static/img/aibot/screen05.jpg"
          >
        </van-swipe-item>
        <van-swipe-item class="swipe-item">
          <img
            class="carousel-img"
            src="../../static/img/aibot/screen06.jpg"
          >
        </van-swipe-item>
<!--        <van-swipe-item class="swipe-item">-->
<!--          <img-->
<!--            class="carousel-img"-->
<!--            src="../../static/img/qianyan/5.png"-->
<!--          >-->
<!--        </van-swipe-item>-->
      </van-swipe>
    </div>
    <div class="foot">
      <img
        class="tip" id="tip"
        src="../../static/img/aibot/tip.png"
      >
      <img
        class="download"
        src="../../static/img/aibot/download.png"
        @click="gotoApk">
    </div>
    <Wxpopup v-if="isWeixin"/>
    <img
      class="load"
      src="../../static/img/loading.gif"
      v-if="loading"
      alt=""
    />
  </div>
</template>

<script>
import {get1} from '../until/request'
import Wxpopup from "@/components/Wxpopup.vue";

export default {
  components: {Wxpopup},
  data() {
    return {
      user_id: '',
      channel: '',
      paramsHref: '',
      paramsRe: '',
      scheme: '',
      isWeixin: false,
      loading: false,
      isApprentice: '2',
      package: ''
    }
  },
  created() {
    const url = location.href
    console.log('Appurl', url)
    if (url.indexOf('?') >= 0) {
      var params = url.substring((url.indexOf('?') + 1), url.length)
      this.paramsHref = params
      console.log('params', this.paramsHref)
      this.paramsRe = params.replace('channel=61', 'channel=1')
      console.log('paramsRe', this.paramsRe)
      if (params !== null && params !== undefined && params !== '') {
        const paramArr = params.split('&')
        for (const index in paramArr) {
          const key = paramArr[index].split('=')[0]
          const val = paramArr[index].split('=')[1]
          console.log('for', key, val)
          switch (key) {
            case 'isApprentice':
              this.isApprentice = val
              break
            case 'scheme': // scheme 是一个参数
              this.scheme = decodeURIComponent(val)
              break
            case 'picName':
              this.picName = val
              break
            case 'package_name':
              this.package = val
              break
            case 'channel':
              this.channel = val
              break
            case 'user_id':
              this.user_id = val
              break
            default:
              break
          }
        }
      }
    }
    this.hideTop()
    console.log('scheme', this.scheme, this.package)
  },
  mounted() {
  },
  methods: {
    gotoApk() {
      let paramsHref = ''
      if (!this.scheme || this.scheme === '') {
        paramsHref = this.paramsRe
        this.scheme = 'airobot://' + '?route=invitation' + '&userid=' + this.user_id
      } else {
        if (this.paramsHref.indexOf('scheme') !== -1) {
          paramsHref = this.paramsHref.substring(0, (this.paramsHref.indexOf('scheme') - 1))
        } else {
          paramsHref = this.paramsHref
        }
      }
      var ua = navigator.userAgent.toLowerCase()
      console.log('ua', ua)
      var isWeixin = ua.indexOf('micromessenger') !== -1
      if (isWeixin) {
        this.isWeixin = true
      } else {
        this.isWeixin = false
        // window.location.href = this.apk
        console.log('deviceType', this.getDeviceType())
        if (this.getDeviceType() === 'android') {
          // const iframes = document.createElement('iframe')
          // //iframes.src = this.scheme
          // iframes.src = 'qianyanai://' + '?route=invitation' + '&userid=' + this.user_id
          // console.log('src', iframes.src)
          // // iframes.setAttribute('style', 'display:none;')
          // iframes.setAttribute('height', '0rem')
          // iframes.setAttribute('width', '0rem')
          // iframes.setAttribute('frameborder', '0')
          // document.body.appendChild(iframes)
          // console.log('iframe', iframes)
          // window.location.href = 'qianyanai://' + '?route=invitation' + '&userid=' + this.user_id
          // window.location.href = 'qianyanai://'
          window.location.href = 'airobot://' + this.package
          this.loading = true

          console.log('paramsHref', paramsHref)
          this.timers = setTimeout(() => {
            this.loading = false
            // const htp = 'https://mtasks.dev.tagtic.cn/'
            const htp = 'https://packapk.gzzhitui.com/'
            window.location.href = htp + 'apk/pack/' + paramsHref
          }, 5000)
        }
        if (this.getDeviceType() === 'ios') {
          // this.getIp()
          // 剪切板内容
          // navigator.clipboard.writeText('charge://' + this.package + '?picName=' + this.picName + '&skuId=' + this.skuId + '&path=' + this.path + '&invite_code=' + this.invite_code)
          navigator.clipboard.writeText('aibotai://?route=invitation' + '&userid=' + this.user_id)
          this.loading = true
          const scheme = this.package.split('.')
          console.log(scheme[scheme.length - 1])
          //  console.log('hrf', scheme[scheme.length - 1] + '://' + this.package)
          // hrf qianyanai://com.mkii.qianyanai
          // window.location.href = scheme[scheme.length - 1] + '://' + this.package
          console.log(scheme[scheme.length - 1] + '://' + '?route=invitation' + '&userid=' + this.user_id)


          const hrf = this.package === 'com.mkii.qianyanai' ? 'itms-apps://apps.apple.com/cn/app/id6450659505' : ''
          window.location.href = hrf
          // window.location.href = scheme[scheme.length - 1] + '://' + '?route=invitation' + '&userid=' + this.user_id
          // this.timers = setTimeout(() => {
          //   this.loading = false
          //   const hrf = this.package === 'com.mkii.qianyanai' ? 'itms-apps://apps.apple.com/cn/app/id6450659505' : ''
          //   window.location.href = hrf
          // }, 1500)
        }

        this.detectionOut()
      }
    },
    hideTop() {
      setTimeout(function () {
        const element = document.getElementById('tip')
        element.style.visibility = 'hidden'
      }, 3000)
    },
    detectionOut() {
      document.addEventListener('visibilitychange', () => {
        var isHidden = document.hidden
        console.log('hidden', isHidden)
        if (isHidden) {
          // 离开页面
          // clearTimeout(this.timers)
          console.log('==离开页面==')
        } else {
          // 进入页面
          console.log('==进入页面==')
          location.reload()
          // clearTimeout(this.timers)
          this.loading = false
        }
      })
      document.addEventListener('cancel', () => {
        var isHidden = document.hidden
        this.loading = false
        console.log('hidden', isHidden)
        if (isHidden) {
          // 离开页面
          clearTimeout(this.timers)
          console.log('==离开页面==')
        } else {
          // 进入页面
          clearTimeout(this.timers)
          this.loading = false
        }
      })

    },
    getDeviceType() {
      const u = navigator.userAgent
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
      if (isAndroid) {
        return 'android'
      }
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      console.log('isiOS', isiOS)
      if (isiOS) {
        return 'ios'
      }
      return 'android'
    },
    async getIp() {
      let result = ''
      result = await get1('/apk/pack/channel=61&package_name=com.mkii.qianyanai&user_id=123456')
      console.log(result)
      // if (result.code === 0) {
      //   //this.list = result.data.list
      // }
    }
  }
}
</script>

<style lang="scss" scoped="scoped">

.ai {
  position: relative;
  width: 100%;
  height: 100vh;

  .title {
    height: 300px;
    width: 100%;
    background: url(../../static/img/aibot/bg.png);
    background-size: 100% 100%;
  }

  .carousel {
    background-color: #FFFFFF;
    position: relative;
    top: -34px;

    .swipe-item {
      position: relative;
      right: 124px;
      left: 16px;
    }

    .carousel-img {
      position: relative;
      width: 255px;
      height: 453px;
    }
  }

  .foot {
    position: fixed;
    bottom: 20px;
    .tip {
      position: relative;
      visibility: hidden;
    //top: -168px;
      height: 82px;
      width: 220px;
    }

    .download {
      position: relative;
    //top: -182px;
      height: 88px;
      width: 311px;
    }
  }

  .load {
    width: 2.8rem;
    height: 2.8rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
